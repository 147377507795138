import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Tracking from '../Dashboard/Tracking';

export default function BPCLINRedirect() {
  const { BPCLIN } = useParams();
  console.log('BPCLIN:', BPCLIN);
  return <>
  <Tracking assessKey={BPCLIN}/>
  </>; 
}
