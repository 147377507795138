/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import rectangle from './../../static/images/icon/sidebar/updated/rectangle.png'
import delivery from './../../static/images/icon/sidebar/updated/delivery-man.png'
import truck from './../../static/images/icon/sidebar/updated/Truck.gif'
import pfs from './../../static/images/icon/sidebar/updated/pfaAnimation.gif'
import beep from './../../static/images/icon/sidebar/updated/jingle.mpeg'
import { logoutOnExpiry, url } from './../../services/requests'
import moment from 'moment'

const Tracking = ({assessKey}) => {
    let audio = new Audio(beep)
    const [trackData, setTrackData] = useState({})
    const [delivery_Instructions, setDeliveryInst] = useState(false)
    const [otp_modal, setotpmodal] = useState(false)
    const [auth, setAuth] = useState(false)
    const [loader, setLoader] = useState(false)
    const [verifyStatus, setVerifyStatus] = useState(false)
    const [newLatLng, setNewLatLng] = useState([])
    const [mssg, setMssg] = useState('')
    const [base64, setBase64] = useState({
        basee: '',
        mssg: ''
    })
    const [otpValues, setOtpValues] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: ""
    });
    const [moveMarker, setMoveMarker] = useState(false)
    const [verify, setVerify] = useState({
        otp: '',
        tripId: '',
        accessKey: ''
    })
    const [addressState, setChangeAddress] = useState({
        otp: '',
        tripId: '',
        requestId: '',
        accessKey: '',
        endLocation: [],
        remarks: ''
    })
    const [delivery, setDelivery] = useState('')
    const [top_modal, set_top_modal] = useState(false)
    const [obj, setObj] = useState({})

    const closeotp_modal = () => {
        setotpmodal(!otp_modal)
    }
    const close_top_modal = () => {
        set_top_modal(!top_modal)
    }
    function handle_next_input(event) {
        var otp_inputs = document.querySelectorAll(".otp__digit")
        var mykey = "0123456789".split("")
        otp_inputs.forEach((_) => {
            _.addEventListener("keyup", handle_next_input)
        })

        let current = event.target
        let index = parseInt(current.classList[1].split("__")[2])
        current.value = event.key

        if (event.keyCode == 8 && index > 1) {
            current.previousElementSibling.focus()
        }
        if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
            var next = current.nextElementSibling;
            next.focus()
        }
        var _finalKey = ""
        for (let { value } of otp_inputs) {
            _finalKey += value
        }
        var splitOtp = _finalKey.split("")
        let temp = { ...otpValues }
        temp.otp1 = splitOtp[0]
        temp.otp2 = splitOtp[1]
        temp.otp3 = splitOtp[2]
        temp.otp4 = splitOtp[3]
        temp.otp5 = splitOtp[4]
        temp.otp6 = splitOtp[5]
        setOtpValues(temp)
    }
    const [schema, setSchema] = useState({
        eta: '',
        address: '',
        mobile: '',
        name: '',
        vehicleNumber: '',
        weight: '',
        deliveryTime: ''
    })
    const openDeliveryModal = () => {
        let temp = { ...verify }
        let combineOtp = `${otpValues.otp1}` + `${otpValues.otp2}` + `${otpValues.otp3}` + `${otpValues.otp4}` + `${otpValues.otp5}` + `${otpValues.otp6}`
        temp.tripId = obj.tripId
        temp.otp = Number(combineOtp)
        temp.accessKey = obj.accessKey
        axios.post(`${url}/trip/checkOtp`, { ...temp })
            .then(response => {
                let status = response.data.success
                let mssg = response.data.message
                if (status) {
                    setMoveMarker(true)
                    setVerifyStatus(true)
                    setDeliveryInst(!delivery_Instructions)
                }
                else {
                    setVerifyStatus(false)
                    setMoveMarker(false)
                    // setOtpValues({
                    //     otp1: "",
                    //     otp2: "",
                    //     otp3: "", 
                    //     otp4: "",
                    //     otp5: "",
                    //     otp6: ""
                    // })
                    window.$.fn.show_notification({ message: `${mssg}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    const save = () => {
        setDeliveryInst(false)
        setotpmodal(!otp_modal)
    }
    const finalSave = () => {
        let temp = { ...addressState }
        temp.otp = `${otpValues.otp1}` + `${otpValues.otp2}` + `${otpValues.otp3}` + `${otpValues.otp4}` + `${otpValues.otp5}` + `${otpValues.otp6}`
        temp.tripId = obj.tripId
        temp.requestId = obj.requestId
        temp.accessKey = obj.accessKey
        temp.endLocation = newLatLng
        temp.remarks = delivery
        setotpmodal(false)
        axios.post(`${url}/trip/changeAddress`, { ...temp })
            .then(response => {
                let status = response.data.success
                if (status === true) {
                    window.location.reload(true)
                }
                else {
                    setMoveMarker(false)
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    const openOTPModal = () => {
        setotpmodal(!otp_modal)
    }
    const closeDelModal = () => {
        setDeliveryInst(!delivery_Instructions)
        setotpmodal(!otp_modal)
    }
    useEffect(() => {
        console.log('tracking====>', obj)
        setLoader(true)
        const query = new URLSearchParams(window.location.search);
        const getToken = query.get('key')
        let finalObj = { accessKey: getToken || assessKey }
        axios.post(`${url}/auth/checkAccessKey`, { ...finalObj })
            .then(response => {
                let temp = { ...schema }
                let status = response && response.data ? response.data.success : ''
                let message = response && response.data ? response.data.message : ''
                let trackingData = response && response.data ? response.data.data : {}
                let cashmemo = response && response.data ? response.data.data.cashmemo : ''
                setObj(trackingData)
                temp.address = trackingData && trackingData.destinationAddress ? trackingData.destinationAddress : ''
                temp.mobile = trackingData && trackingData.driverDetails ? trackingData.driverDetails.mobile : ''
                temp.name = trackingData && trackingData.driverDetails ? trackingData.driverDetails.name : ''
                temp.eta = trackingData && trackingData.eta ? trackingData.eta : '-'
                temp.vehicleNumber = trackingData && trackingData.vehicleNumber ? trackingData.vehicleNumber : '-'
                temp.weight = trackingData && trackingData.weight ? trackingData.weight : '-'
                temp.deliveryTime = trackingData && trackingData.deliveryTime ? trackingData.deliveryTime : '-'
                setSchema(temp)
                let vehNum = trackingData && trackingData.vehicleNumber ? trackingData.vehicleNumber : '-'
                if (status === true) {
                    setLoader(false)
                    setAuth(true)
                    axios.post(`${url}/track/getLocation`, { vehicleNumber: vehNum })
                        .then(response => {
                            let vehLat = response.data.data.latitude
                            let vehLng = response.data.data.longitude
                            marker = new google.maps.Marker({
                                position: new google.maps.LatLng(vehLat, vehLng),
                                map: map,
                                icon: {
                                    url: truck,
                                    scaledSize: new google.maps.Size(65, 65),
                                    anchor: new google.maps.Point(0, 0)
                                }
                            });
                            setTimeout(() => {
                                marker.setMap(null);
                            }, 29400)

                        })
                        .catch(error => {
                            console.log('error', error);
                        })
                    setInterval(() => {
                        axios.post(`${url}/track/getLocation`, { vehicleNumber: vehNum })
                            .then(response => {
                                let vehLat = response.data.data.latitude
                                let vehLng = response.data.data.longitude
                                marker = new google.maps.Marker({
                                    position: new google.maps.LatLng(vehLat, vehLng),
                                    map: map,
                                    icon: {
                                        url: truck,
                                        scaledSize: new google.maps.Size(65, 65),
                                        anchor: new google.maps.Point(0, 0)
                                    }
                                });
                            })
                            .catch(error => {
                                console.log('error', error);
                            })
                    }, 30000)
                    setTrackData(trackingData)
                    let coords = []
                    let waypoints = [];
                    var marker;
                    var markerDest;
                    var mapOptions = {
                        center: new google.maps.LatLng(trackingData.startLocation[0], trackingData.startLocation[1]),
                        zoom: 12
                    }
                    var map = new google.maps.Map(myMap, mapOptions)
                    const directionsService = new google.maps.DirectionsService();
                    coords.push({ lat: trackingData && trackingData.startLocation[1] ? trackingData.startLocation[1] : '', lng: trackingData && trackingData.startLocation[0] ? trackingData.startLocation[0] : '' })
                    coords.push({ lat: trackingData && trackingData.endLocation[1] ? trackingData.endLocation[1] : '', lng: trackingData && trackingData.endLocation[0] ? trackingData.endLocation[0] : '' })
                    let start = { lat: coords[0].lat, lng: coords[0].lng };
                    let end = {
                        lat: coords[1].lat,
                        lng: coords[1].lng,
                    };
                    for (let j = 1; j < coords.length - 1; j++) {
                        waypoints.push({
                            location: { lat: coords[j].lat, lng: coords[j].lng },
                            stopover: true,
                        });
                    }
                    let request = {
                        origin: start,
                        waypoints: waypoints,
                        destination: end,
                        travelMode: google.maps.TravelMode.DRIVING,
                    };
                    directionsService.route(request, function (result, status) {
                        if (status == "OK") {
                            var directionsDisplay = new google.maps.DirectionsRenderer()
                            directionsDisplay.setMap(map)
                            directionsDisplay.setOptions({ suppressMarkers: true, polylineOptions: { strokeColor: '#ff7200' } });
                            directionsDisplay.setDirections(result);
                        }
                    });
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(coords[0].lat, coords[0].lng),
                        map: map,
                        draggable: false,
                        icon: {
                            url: rectangle,
                            scaledSize: new google.maps.Size(110, 60),
                            anchor: new google.maps.Point(40, 52)
                        },
                        label: {
                            text: 'Delivery Partner',
                            color: "#fff",
                            fontSize: "11px",
                            fontWeight: '900',
                            background: 'red'
                        }
                    });
                    markerDest = new google.maps.Marker({
                        position: new google.maps.LatLng(coords[1].lat, coords[1].lng),
                        map: map,
                        draggable: moveMarker ? true : false,
                        icon: {
                            url: rectangle,
                            scaledSize: new google.maps.Size(110, 60),
                            anchor: new google.maps.Point(40, 52)
                        },
                        label: {
                            text: 'Delivery Location',
                            color: "#fff",
                            fontSize: "11px",
                            fontWeight: '900',
                            background: 'red'
                        }
                    });
                    setNewLatLng([coords[1].lng, coords[1].lat])
                    google.maps.event.addListener(markerDest, 'dragend', function () {
                        var newLat = markerDest.getPosition().lat()
                        var newLng = markerDest.getPosition().lng()
                        var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(coords[1].lat, coords[1].lng), new google.maps.LatLng(newLat, newLng));
                        if (distance > 500) {
                            markerDest.setPosition(new google.maps.LatLng(coords[1].lat, coords[1].lng));
                            set_top_modal(!top_modal)
                            setNewLatLng([coords[1].lng, coords[1].lat])
                            setTimeout(function () {
                                set_top_modal(false)
                            }, 1500);
                        }
                        else {
                            setNewLatLng([newLng, newLat])
                        }

                    });
                    var circle = new google.maps.Circle({
                        map: map,
                        radius: 500,    // 10 miles in metres
                        strokeOpacity: 0.8,
                        strokeWeight: 0.7,
                        fillColor: "#add8e6",
                        fillOpacity: 0.45,
                        zIndex: 1,
                        setDraggable: false
                    });
                    var dummyMarker = new google.maps.Marker({
                        position: new google.maps.LatLng(coords[1].lat, coords[1].lng),
                        visible: false,
                        map: map,
                    });
                    circle.bindTo('center', dummyMarker, 'position');


                }
                else {
                    if (message === "Session Expired.") {
                        setLoader(false)
                        setAuth(false)
                        setMoveMarker(false)
                        setMssg('Your Item has been Delivered..')
                        let data = { fileName: cashmemo }
                        axios.post(`${url}/general/getImageToBlob`, { ...data })
                            .then(response => {
                                let base = response && response.data && response.data.data ? response.data.data : ''
                                let message = response && response.data && response.data.message ? response.data.message : ''
                                let blob = b64toBlob(base, 'application/pdf');
                                let ur = URL.createObjectURL(blob);
                                let temp = { ...base64 }
                                temp.basee = ur
                                temp.mssg = message
                                setBase64(temp)

                            })
                            .catch(error => {
                                console.log('error', error);
                            })
                    }
                    else {
                        setLoader(false)
                        setAuth(false)
                        setMssg('No Data found..')
                    }
                }

            })
            .catch(error => {
                setLoader(false)
                setAuth(false)
                setMssg('No Data found..')
                console.log('error', error);
            })
    }, [moveMarker, assessKey])


    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const downloadCash = () => {
        if (base64.basee !== '' || base64.basee !== undefined) {
            window.open(base64.basee, "_blank")
        }
        else {
            window.$.fn.show_notification({ message: `${base64.mssg}`, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    useEffect(() => {
        $(document.body).one('click', function (e) {
            audio.play()
        });
    }, [])

    return (
        <>
            <div className='mapOuter'>
                {loader === false ?
                    auth ?
                        <>
                            <div id="myMap" className='trackingMap'>
                            </div>
                            <>
                                <div className='deliverDetails'>
                                    <div className='ddcAbs'>
                                        <span className='ddcAbsSpan'>Drag and Drop your Location to change within 500 m .</span>
                                    </div>
                                    <div className='ddc'>
                                        <span className='ddcSpan'>Estimated Time of Arrival</span>
                                        <span className='ddcVal'>
                                            {schema.eta !== '-' ?
                                                <>
                                                    {schema.eta ? Math.floor((schema.eta / (60 * 60)) % 24) < 10 ? '0' + Math.floor((schema.eta / (60 * 60)) % 24) + ' hours : ' : Math.floor((schema.eta / (60 * 60)) % 24) + ' hours : ' : '- '}
                                                    {schema.eta ? Math.floor((schema.eta / (60)) % 60) < 10 ? "0" + Math.floor((schema.eta / (60)) % 60) + ' mins' : Math.floor((schema.eta / (60)) % 60) + ' mins' : '-'}
                                                </>
                                                :
                                                <span>Few Minutes</span>
                                            }
                                        </span>
                                    </div>
                                    <div className='ddc'>
                                        <span className='ddcSpan'>Address</span>
                                        <span className='ddcVal ddcEllip'>{schema.address}</span>
                                    </div>
                                    <div className='ddc'>
                                        <span className='ddcSpan'>Rider Details /  Vehicle Number
                                        </span>
                                        <span className='ddcVal'>{schema.name} , {schema.mobile}
                                            <a href={`tel:${schema.mobile}`}><i className="fa fa-phone faPhone"></i></a>
                                        </span>
                                        <span className='ddcVal'>{schema.vehicleNumber}    </span>
                                    </div>
                                    <div className='ddc'>
                                        {mssg !== 'Your Item has been Delivered..' ?
                                            <span className='ddcSpan'>Enter OTP to add Delivery Instructions and Drag Marker</span>
                                            : ''}
                                        {mssg !== 'Your Item has been Delivered..' ?
                                            verifyStatus === true ?
                                                <button className='ddcBtn' style={{ background: 'green' }} onClick={() => finalSave()}> Save</button>
                                                :
                                                <button className='ddcBtn' onClick={() => openOTPModal()} >Enter OTP</button>
                                            :
                                            <span style={{ color: 'green', fontWeight: '900' }}>Delivered</span>
                                        }
                                    </div>
                                </div>
                                <div className='deliverDetailsMobile'>
                                    <div className='ddcAbs'>
                                        <span className='ddcAbsSpan'>Drag and Drop your Location to change within 500 m .</span>
                                    </div>
                                    <div className='dd50'>
                                        <div className='ddc'>
                                            <span className='ddcSpan'>Estimated Time of Arrival</span>
                                            <span className='ddcVal'>
                                                {schema.eta !== '-' ?
                                                    <>
                                                        {schema.eta ? Math.floor((schema.eta / (60 * 60)) % 24) < 10 ? '0' + Math.floor((schema.eta / (60 * 60)) % 24) + ' hours : ' : Math.floor((schema.eta / (60 * 60)) % 24) + ' hours : ' : '- '}
                                                        {schema.eta ? Math.floor((schema.eta / (60)) % 60) < 10 ? "0" + Math.floor((schema.eta / (60)) % 60) + ' mins' : Math.floor((schema.eta / (60)) % 60) + ' mins' : '-'}
                                                    </>
                                                    :
                                                    <span>Few Minutes</span>
                                                }

                                            </span>
                                        </div>
                                        <div className='ddc'>
                                            <span className='ddcSpan'><b>Address</b></span>
                                            <span className='ddcVal ddcEllip'>{schema.address}</span>
                                        </div>
                                    </div>

                                    <div className='dd50'>
                                        <div className='ddc'>
                                            <span className='ddcSpan'>Rider Details /  Vehicle Number
                                            </span>
                                            <span className='ddcVal'>{schema.name} , {schema.mobile}
                                                <a href={`tel:${schema.mobile}`}><i className="fa fa-phone faPhone"></i></a>

                                            </span>
                                            <span className='ddcVal'>{schema.vehicleNumber}
                                            </span>
                                        </div>
                                        <div className='ddc'>
                                            {mssg !== 'Your Item has been Delivered..' ?
                                                <span className='ddcSpan'>Enter OTP to add Delivery Instructions and Drag Marker</span>
                                                : ''}
                                            {mssg !== 'Your Item has been Delivered..' ?
                                                verifyStatus === true ?
                                                    <button className='ddcBtn' style={{ background: 'green' }} onClick={() => finalSave()}> Save</button>
                                                    :
                                                    <button className='ddcBtn' onClick={() => openOTPModal()} >Enter OTP to edit</button>
                                                :
                                                <span style={{ color: 'green', fontWeight: '900' }}>Delivered</span>
                                            }
                                        </div>
                                    </div>


                                </div>
                            </>
                            {otp_modal && (
                                <div className='recover-moadal'>
                                    <div className='recover-overlay' ></div>
                                    <div className='customModasl'>
                                        <i className="fa fa-close ddrclosea" onClick={() => closeotp_modal()}></i>
                                        <div className='orangeHeader' >
                                        </div>
                                        <div className='customReactAddress'>
                                            <span>Enter OTP </span>
                                        </div>
                                        <div className='otpfieldEelm'>
                                            <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__1" />
                                            <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__2" />
                                            <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__3" />
                                            <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__4" />
                                            <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__5" />
                                            <input type="number" pattern="[0-9]*" inputmode="numeric" onChange={(event) => handle_next_input(event)} className="otp__digit otp__field__6" />
                                        </div>
                                        <div className='customReactAddress2' style={{ height: '90px' }}>
                                            <button className='CRB' onClick={() => openDeliveryModal()}>Verify</button>
                                        </div>
                                        <div className='orangeHeaderS'>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {delivery_Instructions && (
                                <div className='recover-moadal'>
                                    {/* <div className='recover-overlay' ></div> */}
                                    <div className='customModasl'>
                                        <i className="fa fa-close ddrclosea" onClick={() => closeDelModal()}></i>
                                        <div className='orangeHeader' >
                                        </div>
                                        <div className='customReactAddress'>
                                            <span>Delivery Instructions</span>
                                        </div>
                                        <div className='customReactAddress1'>
                                            <div className='cRAdd70' style={{ marginBottom: '7px' }}>
                                                {/* <span>1.</span> */}
                                                <input type='text' onChange={(e) => setDelivery(e.target.value)} placeholder='Enter Delivery Instructions...' />
                                            </div>
                                            {/* <div className='cRAdd70'>
                                                <span>2.</span>
                                                <input type='text' placeholder='Enter Delivery Instructions...' />
                                            </div> */}
                                        </div>
                                        <div className='customReactAddress2'>
                                            <button className='CRB' onClick={save} >Save</button>
                                        </div>
                                        <div className='orangeHeaderS'>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {top_modal && (
                                <div className='recover-moadal'>
                                    <div className='customRMssg'>
                                        {/* <i className="fa fa-close top_modal_close" onClick={() => close_top_modal()}></i> */}
                                        <span>Marker cannot be dragged more than 500 m from Delivery Location.</span>
                                    </div>
                                </div>
                            )}
                        </>
                        :
                        <div className='nodatafound'>
                            <span style={{ fontWeight: '900' }}>{mssg}</span>
                            {mssg === 'Your Item has been Delivered..' ?
                                <>
                                    <img src={pfs} className='pfsImg' />
                                    <>
                                        <div className='deliverDetailsTop'>
                                            <div className='ddc ddcLeft' style={{ width: '50%' }}>
                                                <span className='ddcSpan ddcPadd'>Address</span>
                                                <span className='ddcVal ddcEllip ddcPadd'>{schema.address}</span>
                                            </div>
                                            <div className='ddc' style={{ width: '50%', border: 'none' }}>
                                                <span className='ddcSpan '>Rider Details
                                                </span>
                                                <span className='ddcVal'>{schema.name} , {schema.mobile}
                                                    <a href={`tel:${schema.mobile}`}><i className="fa fa-phone faPhone ddcPadd"></i></a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='deliverDetails'>
                                            {/* <div className='ddc'>
                                                <span className='ddcSpan'>Estimated Time of Arrival</span>
                                                <span className='ddcVal'>
                                                    {schema.eta ? Math.floor((schema.eta / (60 * 60)) % 24) < 10 ? '0' + Math.floor((schema.eta / (60 * 60)) % 24) + ' hours : ' : Math.floor((schema.eta / (60 * 60)) % 24) + ' hours : ' : '- '}
                                                    {schema.eta ? Math.floor((schema.eta / (60)) % 60) < 10 ? "0" + Math.floor((schema.eta / (60)) % 60) + ' mins' : Math.floor((schema.eta / (60)) % 60) + ' mins' : '-'}
                                                </span>
                                            </div> */}

                                            <div className='ddc' >
                                                <span className='ddcSpan'>Vehicle Number , Cylinder Weight</span>
                                                <span className='ddcVal ddcEllip'>{schema && schema.vehicleNumber ? schema.vehicleNumber : '-'}, {schema && schema.weight ? schema.weight + ' kg' : '-'} </span>
                                            </div>
                                            <div className='ddc'>
                                                {mssg !== 'Your Item has been Delivered..' ?
                                                    <span className='ddcSpan'>Enter OTP to add Delivery Instructions and Drag Marker</span>
                                                    : ''}
                                                {mssg !== 'Your Item has been Delivered..' ?
                                                    verifyStatus === true ?
                                                        <button className='ddcBtn' style={{ background: 'green' }} onClick={() => finalSave()}> Save</button>
                                                        :
                                                        <button className='ddcBtn' onClick={() => openOTPModal()} >Enter OTP</button>
                                                    :
                                                    <>
                                                        <span style={{ color: 'green', fontWeight: '900' }}>Delivered <span style={{ color: '#000', fontWeight: '400' }}> at  <span style={{ color: 'green', fontWeight: '900' }}>{schema && schema.deliveryTime ? moment(schema.deliveryTime).format("h:mm a") : '-'}</span> </span>
                                                            <button className='allButtons cashMmeoBtn ' onClick={() => downloadCash()}>Download Cash Memo</button>
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className='deliverDetailsMobile'>
                                            <div className='dd50'>
                                                <div className='ddc'>
                                                    <span className='ddcSpan'><b>Vehicle Number</b></span>
                                                    <span className='ddcVal ddcEllip'>{schema.vehicleNumber}</span>
                                                </div>
                                                <div className='ddc'>
                                                    <span className='ddcSpan'>Cylinder Weight
                                                    </span>
                                                    <span className='ddcVal'>{schema && schema.weight ? schema.weight + ' kg' : '-'}
                                                    </span>
                                                </div>

                                            </div>

                                            <div className='dd50'>
                                                <div className='ddc'>
                                                    <span style={{ color: 'green', fontWeight: '900' }}>Delivered <span style={{ color: '#000', fontWeight: '400' }}> at  <span style={{ color: 'green', fontWeight: '900' }}>{schema && schema.deliveryTime ? moment(schema.deliveryTime).format("h:mm a") : '-'}</span></span></span>
                                                </div>
                                                <div className='ddc'>
                                                    {mssg !== 'Your Item has been Delivered..' ?
                                                        <span className='ddcSpan'>Enter OTP to add Delivery Instructions and Drag Marker</span>
                                                        : ''}
                                                    {mssg !== 'Your Item has been Delivered..' ?
                                                        verifyStatus === true ?
                                                            <button className='ddcBtn' style={{ background: 'green' }} onClick={() => finalSave()}> Save</button>
                                                            :
                                                            <button className='ddcBtn' onClick={() => openOTPModal()} >Enter OTP to edit</button>
                                                        :

                                                        <button className='allButtons cashMmeoBtn cashMmeoBtnLeft' onClick={() => downloadCash()}>Download Cash Memo</button>

                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </>
                                </>

                                : ''
                            }
                        </div>
                    :
                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                        <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                    </div>

                }
            </div>
        </>

    );
};

export default Tracking;















